import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { MainLayout, PostCard, PaginationHelper } from '../components/common'
import { MetaData } from '../components/common/meta'
import Img from 'gatsby-image'
import { Card, Avatar, Row, Col, Divider, Typography } from 'antd'
const { Title, Text } = Typography
const { Meta } = Card

/**
* Author page (/author/:slug)
*
* Loads all posts for the requested author incl. pagination.
*
*/
const Author = ({ data, location, pageContext }) => {
    const author = data.author
    // console.log(`author`)
    // console.log(author)
    // const author = pageContext.author
    // const posts = data.allGhostPost.edges
    const twitterUrl = `` //author.twitter ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = `` //author.facebook ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}` : null

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="profile"
            />
            <MainLayout>

                <Row justify="space-around" align="center">
                    <Col span={24} style={{ textAlign: `center` }}>
                        <Card

                        >
                            <Row justify="space-around" align="center">
                                <Col span={4} style={{ textAlign: `center` }}>
                                    {author.image && <Img
                                        fluid={author.image.childImageSharp.fluid}
                                        alt={`Image of author — ${author.fullName}`}
                                    />}
                                </Col>
                                <Col span={20} style={{ textAlign: `center` }}>
                                    <Title level={1}>{author.fullName}</Title>
                                    <Title level={4}>developer • marketer • designer • indie hacker</Title>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {/*
                <header className="author-header">
                    <div className="author-header-content">
                        <h1>{author.fullName}</h1>
                        <div className="author-header-meta">
                            {author.website && <a className="author-header-item" href={author.website} target="_blank" rel="noopener noreferrer">Website</a>}
                            {twitterUrl && <a className="author-header-item" href={twitterUrl} target="_blank" rel="noopener noreferrer">Twitter</a>}
                            {facebookUrl && <a className="author-header-item" href={facebookUrl} target="_blank" rel="noopener noreferrer">Facebook</a>}
                        </div>
                    </div>
                </header>
                */}
                {/*<section className="post-feed">
                    {posts.map(({ node }) => (
                        // The tag below includes the markup for each post - components/common/PostCard.js
                        <PostCard key={node.id} post={node} />
                    ))}
                </section>
                <PaginationHelper pageContext={pageContext} />
                */}
            </MainLayout>
        </>
    )
}

Author.propTypes = {
    data: PropTypes.shape({
        author: PropTypes.shape({
            fullName: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Author

export const pageQuery = graphql`
    query AuthorPage($id: String!) {
      site {
        siteMetadata {
          title
        }
      }
      author: authorJson(id: { eq: $id }) {
          ...AuthorFields
      }
    }
`
